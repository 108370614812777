import { httpClientOmni } from '@/libraries';

export default {
    async getMessage(data) {
        const response = httpClientOmni.post('/ticket/get-message', data);
        return response;
    },
    async getMessageDiscussion(data) {
        const response = httpClientOmni.post('/ticket/get-message-discussion', data);
        return response;
    },
    async getAll(data) {
        const response = await httpClientOmni.post(
            `/ticket/get?sort=&page=${data.page}&per_page=10&filterText=${data.filterText}&agent_id=${data.agent_id}`
        );
        return response;
    },
    async uploadFoto(file) {
        const response = await httpClientOmni.post('/chat/upload-file', file);
        return response;
    },
    async getCount(data) {
        const response = await httpClientOmni.post('/ticket/get-count-ticket-list', data);
        return response;
    },
    async getCountbyAgent(data) {
        const response = await httpClientOmni.post('/ticket/get-count', data);
        return response;
    },
    async getRole() {
        const response = httpClientOmni.get(`/ticket/role/all`);
        return response;
    },
    async reactiveFilesUpdate(data) {
        const response = httpClientOmni.put(`/ticket/update-file/${data.id}`, data);
        return response;
    },
    async getDetailTicket(payload) {
        const response = await httpClientOmni.post('/ticket/edit', payload);
        return response;
    },

    // get status
    async getStatus(payload) {
        let response = await httpClientOmni.get(`/status/get?sort=&page=1&per_page=10&filterText=${payload}`);
        return response;
    },
    async getBranchOffice(value) {
        let response = await httpClientOmni.get(`/branch-office/get?sort=&page=1&per_page=100&filterText=${value}`);
        return response;
    },

    // get Priority
    async getPriority(value) {
        let response = await httpClientOmni.get(`/priority/get?sort=&page=1&per_page=10&filterText=${value}`);
        return response;
    },

    // get Type
    async getType(value) {
        let response = await httpClientOmni.get(`/v3/type/get?sort=&page=1&per_page=10&filterText=${value}`);
        return response;
    },

    async createTicketCategory(params) {
        let response = await httpClientOmni.post(`/v3/category/create`, params);
        return response;
    },

    async updateTicketCategory(params) {
        let response = await httpClientOmni.put(`/v3/category/update/${params.id}`, params);
        return response;
    },

    async deleteTicketCategory(params) {
        let response = await httpClientOmni.delete(`/v3/category/${params.id}`);
        return response;
    },

    // get Subject
    async getSubject(value) {
        let response = await httpClientOmni.get(`/subject/get?sort=&page=1&per_page=10&filterText=${value}`);
        return response;
    },

    // get Track
    async getTrack(value) {
        let response = await httpClientOmni.get(`/track/get?sort=&page=1&per_page=10&filterText=${value}`);
        return response;
    },

    // get Tags
    async getTags(value) {
        let response = await httpClientOmni.get(`/tag/get-ticket?sort=&page=1&per_page=10&filterText=${value}`);
        return response;
    },
    async searchTags(value) {
        let response = await httpClientOmni.get(`/tag/get-ticket?noPaginate=true/filterText=${value}`);
        return response;
    },
    // get Customer
    async getCustomer(value) {
        let response = await httpClientOmni.get(`/user?sort=&page=1&per_page=10&filterText=${value}`);
        return response;
    },

    // get Group Management
    async getGroupManagement(value) {
        let response = await httpClientOmni.get(`/v3/group?sort=&page=1&per_page=10&filter=${value}&status_active=true`);
        return response;
    },
    async getGroupManagementNoPagination(value) {
        let response = await httpClientOmni.get(`/v3/group?paginated=false&filter=${value}&status_active=true`);
        return response;
    },
    async getTicketAll(payload) {
        let response = await httpClientOmni.post(`/ticket/get-all/${payload}`);
        return response;
    },
    async getTicketInbox(payload) {
        if (typeof payload.page != 'undefined') {
            let response = await httpClientOmni.post('/ticket/get-inbox', payload);
            return response;
        } else {
            let response = await httpClientOmni.post('/ticket/get-all-inbox', payload);
            return response;
        }
    },
    async getTicketSent(payload) {
        if (typeof payload.page != 'undefined') {
            let response = await httpClientOmni.post('/ticket/get-sent', payload);
            return response;
        } else {
            let response = await httpClientOmni.post('/ticket/get-all-sent', payload);
            return response;
        }
    },
    async getUserData() {
        try {
            const response = httpClientOmni.get(`/${this.$store.getters.appId.hashed}/user-platform/user-data`);
            return response;
        } catch (error) {
            return this.$ref.lennaModal.show('alert', 'Sorry', error.response.data.message);
        }
    },
    async getFilterText(data) {
        const response = httpClientOmni.post(`/ticket/filter-text`, data);
        return response;
    },
    async filter(data) {
        const response = httpClientOmni.post(`/ticket/filter`, data);
        return response;
    },
    async update(param, data) {
        const response = httpClientOmni.put(`/ticket/${param}`, data);
        return response;
    },
    async getInformation(data) {
        const response = httpClientOmni.get(`/ticket/${data}` + '/get-information');
        return response;
    },
    async destroy(dataChecklist) {
        const response = httpClientOmni.post(`/ticket/destroy`, { dataChecklist });
        return response;
    },
    async getByTicketId(id) {
        const response = httpClientOmni.post(`/ticket/detail`, { id });
        return response;
    },
    async create(data) {
        const response = httpClientOmni.post(`/ticket/create`, data);
        return response;
    },
    getByUserId(userId) {
        const response = httpClientOmni.get(`/user/get-ticket/${userId}`);
        return response;
    },
    getByRoomId(roomId) {
        const response = httpClientOmni.post(`/ticket/get-by-room`, {
            room_id: roomId
        });
        return response;
    },
    checkOnShowTicket(userId) {
        const response = httpClientOmni.get(`/ticket/create-by-user/${userId}`);
        return response;
    },
    updateTicket(data, id) {
        const response = httpClientOmni.put(`ticket/update/${id}`, {
            ...data
        });
        return response;
    },
    async checkStatusOnRevertToOpen(id) {
        const response = httpClientOmni.get(`/ticket/timeline/rollback-agent/${id}`);
        return response;
    },
    async getPrivateNotes(data) {
        const response = httpClientOmni.post(`/ticket/private-note/list`, {
            ticket_id: data,
            seen: 'true'
        });
        return response;
    },
    async countPrivateNotes(data) {
        const response = httpClientOmni.post(`/ticket/private-note/count`, {
            ticket_id: data
        });
        return response;
    },
    async mergeTicketHistory(data) {
        const response = httpClientOmni.post(`/ticket/merge/history`, {
            ticket_id: data
        });
        return response;
    },

    async getTicketChildInquiries(data) {
        const response = httpClientOmni.post(`/ticket/inquiries/cross-content`, {
            ticket_id: data
        });
        return response;
    },

    async getCustomField(data) {
        const response = httpClientOmni.post(`/ticket/custom-field/get`, {
            data: data
        });
        return response;
    },
    async getBrands(data) {
        const response = httpClientOmni.get(`/branch-office/${data}/get-brands`);

        return response;
    },

    async updateAttributes(data) {
        const response = httpClientOmni.post(`/ticket/attributes`, data);
        return response;
    },

    async searchCustomer(filter) {
        let response = await httpClientOmni.get(`/user-search?filterText=${filter}`);

        return response;
    },
    updateSession(data) {
        const response = httpClientOmni.post(`ticket/update-session`, {
            ticket_id: data.ticket_id,
            room_id: data.room_id,
            user_id: data.user_id
        });
        return response;
    },
    async getChannel(data) {
        let response = await httpClientOmni.get(import.meta.env.VITE_OMNICHANNEL_URL + '/api/v3/channel');
        return response;
    },
    /**
     * @module /services/ticketing/ticket_service/setVisitingAgent
     * @desc Ajax request yang digunakan untuk mengirim data agent yang sedang mengunjungi detail sebuah ticket
     *
     * @param {Object} data - Detail object agent
     * @param {string} data.email - Email agent
     * @param {string} data.id - Id agent
     * @param {string} data.name - Nama agent
     * @param {string} data.picture - Url foto profile agent
     * @param {string} data.ticket_id - Ticket id yang sedang dikunjungi oleh agent
     * @param {string} data.status - Action yang menentukan apakah agent sedang masuk atau keluar dari halaman
     */

    setVisitingAgent(data) {
        const response = httpClientOmni.post('ticket/agent-seen', data);
        return response;
    },

    async deleteUserSeen(payload) {
        const response = await httpClientOmni.post('/ticket/delete-user-seen', payload);
        return response;
    },

    async updateShowField(data) {
        const response = httpClientOmni.put(`/ticket/custom-field/show-field`, data);
        return response;
    }
};
