import axios from "axios";
import { axiosGenAiConfig } from "@/config";
import { isObject } from "lodash";
const map = {
  platform: import.meta.env.VITE_APP_URL,
  damcorp: import.meta.env.VITE_DAMCORP_URL,
  bawaslu: import.meta.env.VITE_BAWASLU_URL,
  "pgd-omni": import.meta.env.VITE_PEGADAIAN_URL,
};

const urlTemp = map[window.location.hostname.split(".")[0]];
const httpPlainBotApi = axios.create(axiosGenAiConfig);

const requestHandler = (r) => {
  return r;
};

const responseHandler = (r) => {
  return r;
};

const errorHandler = (e) => {
  if (isObject(e)) {
    if (e.response?.status === 401) {
      localStorage.removeItem("user");
      // window.location.href = import.meta.env.VITE_APP_URL;
      window.location.href = urlTemp;
    }
  }
  return Promise.reject(e);
};

httpPlainBotApi.interceptors.request.use(
  (r) => requestHandler(r),
  (e) => errorHandler(e)
);

httpPlainBotApi.interceptors.response.use(
  (r) => responseHandler(r),
  (e) => errorHandler(e)
);

export default httpPlainBotApi;
