<template>
  <b-modal
    hide-footer
    hide-header
    no-padding
    class="border-0"
    modal-class="lenna-modal"
    body-class="p-0 m-0 border-0"
    content-class="border-0"
    size="lg"
    centered
    v-model="isOpen"
    v-bind="$attrs"
    @hide="handleCloseModal"
    @hidden="handleCloseModal"
  >
    <slot name="modal-header">
      <div
        class="ps-4 pe-3 py-2 bg-primary d-flex justify-content-between rounded-top"
      >
        <h5 class="modal-title text-light my-auto">{{ modalTitle }}</h5>
        <i
          class="ri-close-line text-white fs-4 pointer"
          data-bs-dismiss="modal"
          aria-label="Close"
          @click="handleCloseModal"
          v-if="!hideCloseIcon"
        ></i>
      </div>
    </slot>
    <div class="container py-3 px-4">
      <form @submit.prevent="handleSubmit">
        <div class="row">
          <div class="col-lg-12">
            <div class="row g-3">
              <div class="col-lg-12">
                <div>
                  <label for="tasksTitle-field" class="form-label"
                    >API Service</label
                  >
                  <p class="text-muted">Select Whatsapp Service Provider.</p>
                  <v-select
                    v-model="formData.apiService"
                    :options="option"
                    :reduce="(provider) => provider.value"
                  ></v-select>
                </div>
              </div>
              <div class="col-lg-12">
                <div>
                  <label for="tasksTitle-field" class="form-label"
                    >Whatsapp Name</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Input Whatsapp Name"
                    required
                    v-model="formData.whatsappName"
                  />
                </div>
              </div>
              <div class="col-lg-12">
                <div>
                  <label for="tasksTitle-field" class="form-label"
                    >Whatsapp Number</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Input Whatsapp Number"
                    required
                    v-model="formData.whatsappNumber"
                  />
                </div>
              </div>

              <div v-if="formData.apiService == 'apiwha'">
                <div class="col-lg-12">
                  <div>
                    <label for="tasksTitle-field" class="form-label"
                      >API Key</label
                    >
                    <textarea
                      class="form-control"
                      placeholder="Input API Key"
                      required
                      v-model="formData.apiKey"
                      rows="3"
                      spellcheck="false"
                    ></textarea>
                  </div>
                </div>
              </div>

              <div v-if="formData.apiService == 'damcorp'">
                <div class="col-lg-12">
                  <div>
                    <label for="tasksTitle-field" class="form-label"
                      >API Key</label
                    >
                    <textarea
                      class="form-control"
                      placeholder="Input API Key"
                      required
                      v-model="formData.apiKey"
                      rows="3"
                      spellcheck="false"
                    ></textarea>
                  </div>
                </div>
              </div>

              <div v-if="formData.apiService == 'official'">
                <div class="col-lg-12">
                  <div>
                    <label for="tasksTitle-field" class="form-label"
                      >Base URL</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Input Base URL"
                      required
                      v-model="formData.whatsappBaseUrl"
                    />
                  </div>
                </div>

                <div class="col-lg-12">
                  <div>
                    <label for="tasksTitle-field" class="form-label"
                      >Namspace HSM
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Input Namespace HSM"
                      required
                      v-model="formData.namespaceHsm"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div>
                    <label for="tasksTitle-field" class="form-label"
                      >Username
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Input Username"
                      required
                      v-model="formData.username"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div>
                    <label for="tasksTitle-field" class="form-label"
                      >Passwords</label
                    >
                    <input
                      type="password"
                      class="form-control"
                      placeholder="Input Passwords"
                      required
                      v-model="formData.password"
                      autocomplete="off"
                    />
                  </div>
                </div>
              </div>

              <div v-if="formData.apiService == 'infobip'">
                <div class="col-lg-12">
                  <div>
                    <label for="tasksTitle-field" class="form-label"
                      >API Key</label
                    >
                    <textarea
                      class="form-control"
                      placeholder="Input API Key"
                      required
                      v-model="formData.apiKey"
                      rows="3"
                      spellcheck="false"
                    ></textarea>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div>
                    <label for="tasksTitle-field" class="form-label"
                      >Scenario Key</label
                    >
                    <textarea
                      class="form-control"
                      placeholder="Input API Key"
                      required
                      v-model="formData.scenarioKey"
                      rows="3"
                      spellcheck="false"
                    ></textarea>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div>
                    <label for="tasksTitle-field" class="form-label"
                      >Scenario Key</label
                    >
                    <input
                      class="form-control"
                      placeholder="https://{yourId}.api.infobip.com"
                      required
                      v-model="formData.whatsappBaseUrl"
                    />
                  </div>
                </div>
              </div>

              <div v-if="formData.apiService == 'wappin'">
                <div class="col-lg-12">
                  <div>
                    <label for="tasksTitle-field" class="form-label"
                      >Secret Key</label
                    >
                    <input
                      class="form-control"
                      placeholder="Input Secret Key"
                      required
                      v-model="formData.secretKey"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div>
                    <label for="tasksTitle-field" class="form-label"
                      >Client Key</label
                    >
                    <input
                      class="form-control"
                      placeholder="Input Client ID"
                      required
                      v-model="formData.clientId"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div>
                    <label for="tasksTitle-field" class="form-label"
                      >Username</label
                    >
                    <input
                      class="form-control"
                      placeholder="Input Username"
                      required
                      v-model="formData.username"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div>
                    <label for="tasksTitle-field" class="form-label"
                      >Password</label
                    >
                    <input
                      class="form-control"
                      placeholder="Input Password"
                      required
                      v-model="formData.password"
                      autocomplete="off"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-12" v-if="formData.webhookUrl">
                <div>
                  <label for="tasksTitle-field" class="form-label"
                    >Webhook URL</label
                  >
                  <p class="text-muted">
                    You can use this webhook URL to get the callback from
                    Whatsapp API service.
                  </p>

                  <input
                    type="text"
                    readonly=""
                    id="webhook-url"
                    v-model="formData.webhookUrl"
                    class="form-control"
                    required=""
                  />
                  <i
                    class="ri-file-copy-line img-prepend pointer"
                    @click="handleCopy"
                  ></i>
                </div>
              </div>
              <div class="col-lg-12">
                <div>
                  <label for="tasksTitle-field" class="form-label">
                    Catalog Id
                  </label>

                  <input
                    v-model="formData.catalogId"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-lg-12" v-if="formData.webhookUrl">
                <div>
                  <label for="tasksTitle-field" class="form-label">Bot Type</label>
                  <v-select
                    label="name"
                    v-model="formData.bot_type"
                    :options="['normal', 'assistant']"
                    @option:selected="changeValueBot"
                  ></v-select>
                </div>
              </div>
              <div class="col-lg-12" v-if="formData.webhookUrl">
                <div>
                  <label for="tasksTitle-field" class="form-label">Bot</label>
                  <v-select
                    label="name"
                    v-model="formData.bot"
                    :options="listBot"
                    :reduce="(provider) => provider.id"
                    @option:selected="changeValueBot"
                  ></v-select>
                </div>
              </div>
              <div class="col-lg-12">
                <div>
                  <label class="form-label">Business Unit</label>
                  <v-select
                    label="label"
                    name="bussines_unit"
                    class="bg-white"
                    :options="businessUnit"
                    :placeholder="`Select bussines unit`"
                    v-model="business_unit"
                    @option:selected="changeValueBusinessUnit"
                  />
                </div>
              </div>
              <div class="col-lg-12 mt-3">
                <div>
                  <label class="form-label">Groups</label>
                  <v-select
                    label="label"
                    name="group_management"
                    class="bg-white"
                    :options="groupManagement"
                    :placeholder="`Select group`"
                    v-model="group"
                    @option:selected="changeValueGroupManagement"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4 hstack gap-2 justify-content-end">
          <base-button type="submit" :variant="'primary'" :loading="isLoading">
            Submit
          </base-button>
          <base-button
            v-if="
              (localStorage.user.email == 'prod@lenna.ai' ||
                localStorage.user.email ==
                  'sinergidigitalteknologi@gmail.com') &&
              formData.status
            "
            variant="warning"
            @click="setWebhook"
          >
            Set Webhook
          </base-button>
        </div>
      </form>
    </div>
  </b-modal>
</template>

<script>
import vSelect from "vue-select";
import { debounce } from "lodash";
import { mapGetters } from "vuex";
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
    },
    modalTitle: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    vSelect,
  },
  computed: {
    ...mapGetters({
      botList: "bot/botList",
      assistantList: 'gen-ai/assistant',
      businessUnit: "ticket/getBranchOffice",
      groupManagement: "ticket/getGroupManagement",
    }),
    localStorage() {
      return this.$store.getters.localStorage;
    },
    listBot() {
      if (this.form.bot_type == 'assistant') {
        return this.assistantList;
      } else {
        return this.botList;
      }
    }
  },
  data() {
    return {
      isOpen: this.showModal,
      debounce,
      isOpen: this.showModal,
      formData: {},
      groups: [],
      option: [
        {
          label: "Whatsapp Business APIWHA",
          value: "apiwha",
        },
        {
          label: "Whatsapp Business DAMCORP",
          value: "damcorp",
        },
        {
          label: "Whatsapp Business INFOBIP",
          value: "infobip",
        },
        {
          label: "Whatsapp Business OFFICIAL",
          value: "official",
        },
        {
          label: "Whatsapp Business WAPPIN",
          value: "wappin",
        },
      ],
      business_unit: null,
      business_unit_id: null,
      group_id: null,
      group: null,
    };
  },
  watch: {
    showModal(newVal) {
      if (newVal) {
        this.onShowModal();
      } else {
        this.onHideModal();
      }
    },
    form(newVal) {
      this.formData = newVal;
      this.business_unit = newVal.business_unit
        ? newVal.business_unit.branch_office
        : null;
      this.group = newVal.group ? newVal.group.name : null;
    },
  },
  methods: {
    onShowModal() {
      this.isOpen = true;
    },
    onHideModal() {
      this.isOpen = false;
      this.$emit("onClose");
    },
    changeValueBot(val) {
      this.formData.bot = val.id;
    },
    changeValueGroupManagement(val) {
      this.group = val.label;
      this.formData.group_id = val.value;
      this.formData.group = val.label;
    },
    changeValueBusinessUnit(val) {
      this.business_unit = val.label;
      this.formData.business_unit_id = val.id;
      this.formData.business_unit = val.label;
    },
    handleCloseModal() {
      this.$emit("onClose");
    },
    async handleSubmit() {
      console.log("form ", this.formData);
      await this.submit(this.formData, this);
    },
    submit: debounce(async (data, vm) => {
      vm.$emit("onSubmit", data);
    }, 300),
    handleCopy() {
      let copyCode = document.querySelector("#webhook-url");
      copyCode.setAttribute("type", "text");
      copyCode.select();
      document.execCommand("copy");
    },
    setWebhook() {
      this.debounceWebhook(this);
    },
    debounceWebhook: debounce((vm) => {
      vm.configWebhook();
    }, 300),
    async configWebhook() {
      try {
        let response = await this.$axios.post(
          `api/${this.$store.getters.appId.hashed}/set-wa-webhook`,
          {
            integrationId: this.formData.integration_id,
            webhookUrl: this.formData.webhookUrl,
          }
        );
        this.successToast(response.data.message);
      } catch (error) {
        this.errorToast(error);
      }
    },
  },
  mounted() {
    this.emitter.on("form:reset", () => {
      this.resetObjectValue(this.formData);
    });
  },
};
</script>

<style>
.img-prepend {
  position: relative;
  float: right;
  right: 15px;
  bottom: 28px;
  width: 20px;
  height: 20px;
}
</style>
