// import Vue from "vue";
// import VueCookies from "vue-cookies";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();
// Vue.use(VueCookies);
const botId = cookies.get("lenna_bot_id");
// const botId = store.getters["bot/botId"].hashed;
const map = {
  platform: import.meta.env.VITE_APP_URL,
  damcorp: import.meta.env.VITE_DAMCORP_URL,
  bawaslu: import.meta.env.VITE_BAWASLU_URL,
  "pgd-omni": import.meta.env.VITE_PEGADAIAN_URL,
};

const urlTemp = map[window.location.hostname.split(".")[0]];
const appId = window.location.pathname.split("/")[1];
// const getEnv = (key) => import.meta.env[key];

export default {
  api: `${import.meta.env.VITE_OMNICHANNEL_URL}/api/${appId}`,
  upload: `${import.meta.env.VITE_OMNICHANNEL_URL}/upload`,
  botApi: `${import.meta.env.VITE_BOTSTUDIO_URL}/api/${botId}`,
  botUrl: import.meta.env.VITE_BOTSTUDIO_URL,
  appUrl: import.meta.env.VITE_OMNICHANNEL_URL,
  // baseUrl: import.meta.env.VITE_APP_URL"),
  baseUrl: urlTemp,
  authUrl: `${import.meta.env.VITE_AUTH_URL}/api`,
  plainApi: `${import.meta.env.VITE_OMNICHANNEL_URL}/api`,
  plainBotApi: `${import.meta.env.VITE_BOTSTUDIO_URL}/api`,
  webchatUrl: `${import.meta.env.VITE_WEBCHAT_URL}`,
};
