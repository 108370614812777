const genRoute = [
  {
    path: "/:appId/gen-studio",
    name: "gen-studio.gen-studio",
    component: () => import("@/views/gen-studio/pages/ListAssistant.vue"),
    meta: { title: "Gen AI Studio" },
    redirect: {
      name: "gen-studio.gen-studio.assistant",
    },
  },
  {
    path: "/:appId/gen-studio/:assistantId",
    name: "gen-studio-detail",
    component: () => import("@/views/gen-studio/pages/Assistant.vue"),
    meta: { title: "Gen AI Studio" },
  },
  {
    path: "/:appId/gen-studio/assistant/create",
    name: "gen-studio.gen-studio.create",
    component: () =>
      import("@/views/gen-studio/components/assistant/Create.vue"),
    meta: { title: "Create your Bot Assistant" },
  },
  {
    path: "/:appId/gen-studio/assistant",
    name: "gen-studio.gen-studio.assistant",
    component: () => import("@/views/gen-studio/pages/ListAssistant.vue"),
    meta: { title: "Assistant" },
  },
  {
    path: "/:appId/gen-studio/knowledge-center",
    name: "gen-studio.gen-studio.knowledge-center",
    component: () => import("@/views/gen-studio/pages/KnowledgeCenter.vue"),
    meta: { title: "Knowledge Center" },
  },
  {
    path: "/:appId/gen-studio/bot-log",
    name: "gen-studio.gen-studio.bot-log",
    component: () => import("@/views/gen-studio/pages/BotLogs.vue"),
    meta: { title: "Bot Log" },
  },
];
export default genRoute;
