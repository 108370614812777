import { httpClientOmni } from "@/libraries";
import {
  ChannelService,
  UserChannelService,
  TicketService,
  UserService,
} from "@/services";

const Types = {
  SET_LOADING: "SET_LOADING",
};

const state = {
  customer: [],
  channels: [],
  tags: [],
  tickets: [],
  broadcast: [],
  recent_chat: [],
  branch_office: [],
  associatedAccount: [],
};

const getters = {
  getAssociatedAccount(state) {
    return state.associatedAccount;
  },
  getCustomer(state) {
    // console.log("state customer ", state.customer);
    return state.customer;
  },
  getTags(state) {
    return state.tags;
  },
  channels(state) {
    return state.channels;
  },
  getTickets(state) {
    return state.tickets;
  },
  getBroadcastMessage(state) {
    return state.broadcast;
  },
  getRecentChat(state) {
    return state.recent_chat;
  },
  selectedChannels(state) {
    return state.channels;
  },
  delete(state) {
    return state.customer;
  },
  getBranchOffice(state) {
    return state.branch_office;
  },
};

const mutations = {
  GET_ASSOCIATEDACCOUNT: (state, payload) => {
    state.associatedAccount = payload;
  },
  GET_CUSTOMER: (state, payload) => {
    state.customer = payload;
  },
  GET_TAGS: (state, payload) => {
    state.tags = payload;
  },
  SET_CUSTOMER: (state, payload) => {
    state.customer = payload;
  },
  CREATE_CUSTOMER: (state, payload) => {
    state.customer = payload;
  },
  GET_CHANNELS: (state, payload) => {
    state.channels = payload;
  },
  GET_TICKET: (state, payload) => {
    state.tickets = payload;
  },
  GET_BROADCAST_MESSAGE: (state, payload) => {
    state.broadcast = payload;
  },
  GET_RECENT_CHAT: (state, payload) => {
    state.recent_chat = payload;
  },
  SET_SELECTED_CHANNELS: (state, payload) => {
    state.channels = payload;
  },
  DELETE_CUSTOMER: (state, payload) => {
    state.customer = payload;
  },
  [Types.SET_LOADING]: (state, payload) => (state.loading = payload),
};

const actions = {
  async getAssociatedAccount(context, hashed_id) {
    let response = await httpClientOmni.get(
      `/user/get-associated-account/${hashed_id}`
    );
    context.commit("GET_ASSOCIATEDACCOUNT", response.data);
  },
  async getCustomer(context, hashed_id) {
    let response = await httpClientOmni.get(`/user/id/${hashed_id}`);
    context.commit("GET_CUSTOMER", response.data);
  },
  async getCustomer2(context, hashed_id) {
    let url = import.meta.env.VITE_OMNICHANNEL_URL + `/api/user/${hashed_id}`;
    let response = await httpClientOmni.get(url);
    console.log("when fetch data", response.data);
    context.commit("GET_CUSTOMER", response.data);
  },
  async getTags(context, value) {
    let response = await httpClientOmni.get(
      `/tag/get-ticket?sort=&page=1&per_page=10&filterText=${value}`
    );
    let filters = response.data.data.map(function (item) {
      return {
        name: item.name,
      };
    });
    context.commit("GET_TAGS", filters);
  },
  async getTickets(context, hashed_id) {
    const { data: response } = await TicketService.getByUserId(hashed_id);
    context.commit("GET_TICKET", response.data);
  },
  async getBroadcastMessage(context, hashed_id) {
    let response = await httpClientOmni.get(
      `/user/get-broadcast-message/${hashed_id}`
    );
    context.commit("GET_BROADCAST_MESSAGE", response.data);
  },
  async getRecentChat(context, hashed_id) {
    let response = await httpClientOmni.get(
      `/user/get-all-recent-chat/${hashed_id}`
    );
    context.commit("GET_RECENT_CHAT", response.data.data);
  },
  async getRecentChatFromTicket(context, ticket_id, from_ticket = true) {
    let response = await httpClientOmni.get(
      `/user/get-recent-chat/${ticket_id}/${from_ticket}`
    );
    context.commit("GET_RECENT_CHAT", response.data.data);
  },
  async create(context, payload) {
    let response = await httpClientOmni.post(`/user/store`, payload);
    context.commit("CREATE_CUSTOMER", response.data);
  },
  async store(context, payload) {
    let response = await httpClientOmni.post(
      `/user/store/${payload.hashed_id}`,
      payload
    );
    context.commit("SET_CUSTOMER", response.data);
  },
  async delete(context, id) {
    let response = await httpClientOmni.get(`/user/delete/${id}`);
    context.commit("DELETE_CUSTOMER", response.data);
  },
  async getChannels(context) {
    const response = await ChannelService.getV3Channel();
    console.log(response.data);
    context.commit("GET_CHANNELS", response.data);
  },
  async getBranchOffice({ commit }) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await TicketService.getBranchOffice();
      // eslint-disable-next-line no-inner-declarations
      function mapping(item) {
        return {
          value: item.slug,
          label: item.branch_office,
        };
      }
      let filters = response.data.map(mapping);
      commit(Types.FETCH_DATA_BRANCH_OFFICE, filters);
      // return filters;
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },

  setSelectedChannels({ commit }, payload) {
    commit("SET_SELECTED_CHANNELS", payload);
  },
  async getWaUserList(context, payload) {
    try {
      const response = await UserChannelService.getWaUserList(payload);
      return response;
    } catch (error) {
      console.log("error getWaUserList", error);
    }
  },
  async filterCustomer({ commit }, payload) {
    const response = await UserService.filterCustomer(payload);
    if (response.status == 200) {
      const data = response.data.data;
      commit("GET_CUSTOMER", data);
      return data;
    }
  },
  async blockCustomer(context, payload) {
    try {
      const response = await UserService.block(payload);
      if (response.status == 200) {
        return response;
      }
    } catch (error) {
      console.log("error block customer", error);
    }
  },
  async unblockCustomer(context, payload) {
    try {
      const response = await UserService.unBlock(payload);
      if (response.status == 200) {
        return response;
      }
    } catch (error) {
      console.log("error unblock customer", error);
    }
  },
  async getUserListByEmail(context, payload) {
    try {
      const response = await UserChannelService.getUserListByEmail(payload);
      if (response.status == 200) {
        return response;
      }
    } catch (error) {
      console.log("error get customer by email", error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
