// import TransactionPage from '../pages/TransactionPage'
// import SalesMainPage from '../pages/SalesMainPage'
// import ReportUser from "@/views/omni-messaging/reporting/pages/ReportUser";
// import ReportingMainPage from "@/views/reporting/pages/ReportingMainPage";
// import ReportMessages from "@/views/omni-messaging/reporting/pages/ReportMessages";
// import ReportRoomMsg from "@/views/omni-messaging/reporting/pages/ReportRoomMsg";
// import ReportAgentPerformance from "@/views/omni-messaging/reporting/pages/ReportAgentPerformance";
// import ReportHsm from "@/views/omni-messaging/reporting/pages/ReportHsm";
// import ReportHsmDetail from "@/views/omni-messaging/reporting/pages/ReportHsmDetail";
// import ReportTag from "@/views/omni-messaging/reporting/pages/ReportTag";
// import Report from "@/views/omni-messaging/reporting/pages/Report";
// import ReportTest from "@/views/omni-messaging/reporting/pages/ReportTest";
// import ReportNotes from "@/views/omni-messaging/reporting/pages/ReportNotes";
// import ReportTimeServe from "@/views/omni-messaging/reporting/pages/ReportTimeServe";
// import ReportHandleRoomByAdmin from "@/views/omni-messaging/reporting/pages/ReportHandleRoomByAdmin";
// import ReportBroadcast from "@/views/omni-messaging/reporting/pages/ReportBroadcast";
// import ReportBroadcastMobile from "@/views/omni-messaging/reporting/pages/ReportBroadcastMobile";
// import ReportBroadcastWhatsapp from "@/views/omni-messaging/reporting/pages/ReportBroadcastWhatsapp";
// import ReportRoleHistoryActivity from "@/views/omni-messaging/reporting/pages/ReportRoleHistoryActivity";
// import ReportAgentActivities from "@/views/omni-messaging/reporting/pages/ReportAgentActivities";
// import
const reportingRoute = [
  {
    path: "/:appId/report",
    name: "reports",
    component: () => import("@/views/reporting/pages/ReportingMainPage.vue"),
    meta: {
      title: "Reporting",
    },
  },
  {
    path: "/:appId/report/agent",
    name: "reports.agent",
    component: () => import("@/views/reporting/pages/ReportingMainPage.vue"),
    meta: {
      title: "Reporting Agent",
    },
  },
  {
    path: "/:appId/report/agent/performance",
    name: "reports.agent.performance",
    component: () =>
      import("@/views/reporting/pages/cms/ReportAgentPerformance.vue"),
    meta: {
      title: "Reporting Agent Performance",
    },
  },
  {
    path: "/:appId/report/agent/served",
    name: "reports.agent.served",
    component: () => import("@/views/reporting/pages/cms/ReportTimeServed.vue"),
    meta: {
      title: "Reporting Served Agent",
    },
  },
  // {
  //   path: "/:appId/report/agent/handle-admin",
  //   name: "reports.agent.handle-admin",
  //   component: () => import("@/views/reporting/pages/ReportingMainPage.vue"),
  //   meta: {
  //     title: "Handled By Admin",
  //   },
  // },
  {
    path: "/:appId/report/agent/ticket-sla",
    name: "reports.agent.ticket-sla",
    component: () =>
      import("@/views/reporting/pages/cms/ReportAgentTicketSLA.vue"),
    meta: {
      title: "Report Ticket SLA",
    },
  },
  // {
  //   path: "/:appId/report/agent/time-log",
  //   name: "reports.agent.time-log",
  //   component: () => import("@/views/reporting/pages/ReportingMainPage.vue"),
  //   meta: {
  //     title: "Time Agent Log",
  //   },
  // },
  {
    path: "/:appId/report/agent/activity",
    name: "reports.agent.activity",
    component: () => import("@/views/reporting/pages/cms/AgentActivity.vue"),
    meta: {
      title: "Agent Activity",
    },
  },
  // {
  //   path: "/:appId/report/agent/sla-agent",
  //   name: "reports.agent.sla-agent",
  //   component: () => import("@/views/reporting/pages/ReportSLAAgent.vue"),
  //   meta: {
  //     title: "Report SLA agent",
  //   },
  // },
  {
    path: "/:appId/report/botvsagent",
    name: "reports.agent.botvsagent",
    component: () => import("@/views/reporting/pages/cms/ReportBotVsAgent.vue"),
    meta: {
      title: "Bot vs Agent",
    },
  },
  {
    path: "/:appId/report/broadcast",
    name: "reports.broadcast",
    component: () => import("@/views/reporting/pages/ReportingMainPage.vue"),
    meta: {
      title: "Reporting Broadcast",
    },
  },
  {
    path: "/:appId/report/broadcast/main",
    name: "reports.broadcast.main",
    component: () => import("@/views/reporting/pages/ReportingMainPage.vue"),
    meta: {
      title: "Report Broadcast",
    },
  },
  {
    path: "/:appId/report/broadcast/whatsapp-hsm",
    name: "reports.broadcast.whatsapp-hsm",
    component: () => import("@/views/reporting/pages/cms/ReportHsm.vue"),
    meta: {
      title: "Whatsapp HSM Report",
    },
  },
  {
    path: "/:appId/report/broadcast/whatsapp-hsm-template-usage",
    name: "reports.broadcast.whatsapp-hsm-template-usage",
    component: () =>
      import("@/views/reporting/pages/cms/ReportHsmTemplateUsage.vue"),
    meta: {
      title: "Whatsapp HSM Report",
    },
  },
  {
    path: "/:appId/report/conversation",
    name: "reports.conversation",
    component: () => import("@/views/reporting/pages/ReportingMainPage.vue"),
    meta: {
      title: "Conversation & Ticket",
    },
  },
  {
    path: "/:appId/report/conversation.customer",
    name: "reports.conversation.customer",
    component: () => import("@/views/reporting/pages/cms/ReportCustomer.vue"),
    meta: {
      title: "Customer",
    },
  },
  {
    path: "/:appId/report/conversation.message",
    name: "reports.conversation.message",
    component: () => import("@/views/reporting/pages/cms/ReportMessage.vue"),
    meta: {
      title: "Message",
    },
  },
  {
    path: "/:appId/report/conversation.report-collected-response",
    name: "reports.conversation.report-collected-response",
    component: () =>
      import("@/views/transaction/pages/CollectedResponsePage.vue"),
    meta: {
      title: "Collected Response",
    },
  },
  {
    path: "/:appId/report/conversation/booking",
    name: "reports.conversation.report-booking",
    component: () => import("@/views/transaction/pages/ReportBookingPage.vue"),
    meta: {
      title: "Booking",
    },
  },
  {
    path: "/:appId/report/conversation/bot-stories",
    name: "reports.conversation.report-bot-stories",
    component: () => import("@/views/reporting/pages/cms/ReportBotStories.vue"),
    meta: {
      title: "Bot Stories",
    },
  },
  {
    path: "/:appId/report/conversation.room-message",
    name: "reports.conversation.room-message",
    component: () =>
      import("@/views/reporting/pages/cms/ReportRoomMessage.vue"),
    meta: {
      title: "Room Messages",
    },
  },
  {
    path: "/:appId/report/conversation.interaction",
    name: "reports.conversation.interaction",
    component: () =>
      import("@/views/reporting/pages/cms/ReportInteraction.vue"),
    meta: {
      title: "Interaction",
    },
  },
  {
    path: "/:appId/report/conversation.conversation-tag",
    name: "reports.conversation.conversation-tag",
    component: () =>
      import("@/views/reporting/pages/cms/ReportConversationTag.vue"),
    meta: {
      title: "Conversation Tag",
    },
  },
  {
    path: "/:appId/report/conversation.conversation-note",
    name: "reports.conversation.conversation-note",
    component: () =>
      import("@/views/reporting/pages/cms/ReportConversationNotes.vue"),
    meta: {
      title: "Conversation Note",
    },
  },
  {
    path: "/:appId/report/conversation/chatbot",
    name: "reports.conversation.chatbot",
    component: () => import("@/views/reporting/pages/cms/ChatbotLivechat.vue"),
    meta: {
      title: "Chatbot",
    },
  },
  {
    path: "/:appId/report/conversation/ticket-report", // ticket report
    name: "reports.conversation.ticket-report",
    component: () => import("@/views/reporting/pages/v3/ReportTicket.vue"),
    meta: {
      title: "Ticket Report",
    },
  },
  {
    path: "/:appId/report/conversation/livechat-report", // ticket report
    name: "reports.conversation.livechat-report",
    component: () => import("@/views/reporting/pages/cms/LivechatReport.vue"),
    meta: {
      title: "Livechat Report",
    },
  },
  {
    path: "/:appId/report/log",
    name: "reports.log",
    component: () => import("@/views/reporting/pages/ReportingMainPage.vue"),
    meta: {
      title: "Log",
    },
  },
  {
    path: "/:appId/report/log/soap",
    name: "reports.log.soap",
    component: () => import("@/views/reporting/pages/ReportingMainPage.vue"),
    meta: {
      title: "Soap and API Logs",
    },
  },
  {
    path: "/:appId/report/log/audit-trail",
    name: "reports.log.audit-trail",
    component: () => import("@/views/reporting/pages/ReportingMainPage.vue"),
    meta: {
      title: "Audit Trails",
    },
  },
  {
    path: "/:appId/report/log/role-history",
    name: "reports.log.role-history",
    component: () =>
      import("@/views/reporting/pages/ReportRoleHistoryActivity.vue"),
    meta: {
      title: "Role History Activity",
    },
  },
  {
    path: "/:appId/report/security",
    name: "reports.security",
    component: () => import("@/views/reporting/pages/ReportingMainPage.vue"),
    meta: {
      title: "Security",
    },
  },
  {
    path: "/:appId/report/security/block-access",
    name: "reports.security.block-access",
    component: () => import("@/views/reporting/pages/ReportingMainPage.vue"),
    meta: {
      title: "Report Block Access User",
    },
  },
  {
    path: "/:appId/report/security/block-fund",
    name: "reports.security.block-fund",
    component: () => import("@/views/reporting/pages/ReportingMainPage.vue"),
    meta: {
      title: "Report Source Of Fund Blocker",
    },
  },
  {
    path: "/:appId/report/bot-stories",
    name: "reports.bot-report.bot-stories",
    component: () => import("@/views/reporting/pages/cms/ReportBotStories.vue"),
    meta: {
      title: "Bot Stories",
    },
  },
  // {
  //   path: "report-test",
  //   name: "reporting.report.test",
  //   component: () => import("@/views/reporting/pages/ReportingMainPage.vue"),
  // },
  // {
  //   path: "reports/user",
  //   name: "reporting.user",
  //   component: () => import("@/views/reporting/pages/ReportingMainPage.vue"),
  // },
  // {
  //   path: "reports/messages",
  //   name: "reporting.messages",
  //   component: () => import("@/views/reporting/pages/ReportingMainPage.vue"),
  // },
  // {
  //   path: "reports/room-msg",
  //   name: "reporting.room-msg",
  //   component: () => import("@/views/reporting/pages/ReportingMainPage.vue"),
  // },
  // {
  //   path: "reports/agent-performance",
  //   name: "reporting.agent-performance",
  //   component: () => import("@/views/reporting/pages/ReportingMainPage.vue"),
  // },
  // {
  //   path: "reports/hsm",
  //   name: "reporting.hsm",
  //   component: () => import("@/views/reporting/pages/ReportingMainPage.vue"),
  // },
  // {
  //   path: "reports/hsm-detail/:id",
  //   name: "reporting.hsm.detail",
  //   component: () => import("@/views/reporting/pages/ReportingMainPage.vue"),
  // },
  // {
  //   path: "reports/tag",
  //   name: "reporting.tag",
  //   component: () => import("@/views/reporting/pages/ReportingMainPage.vue"),
  // },
  // {
  //   path: "reports/time-serve",
  //   name: "reporting.time-serve",
  //   component: () => import("@/views/reporting/pages/ReportingMainPage.vue"),
  // },
  // {
  //   path: "reports/note",
  //   name: "reporting.note",
  //   component: () => import("@/views/reporting/pages/ReportingMainPage.vue"),
  // },
  // {
  //   path: "reports/handle-room",
  //   name: "reporting.handle-room",
  //   component: () => import("@/views/reporting/pages/ReportingMainPage.vue"),
  // },
  // {
  //   path: "broadcast-message",
  //   name: "reporting.broadcast-message",
  //   component: () => import("@/views/reporting/pages/ReportingMainPage.vue"),
  //   props: true,
  //   redirect: {
  //     name: "reporting.broadcast-message.mobile",
  //   },
  //   children: [
  //     {
  //       path: "mobile",
  //       name: "reporting.broadcast-message.mobile",
  //       component: () =>
  //         import("@/views/reporting/pages/ReportingMainPage.vue"),
  //     },
  //     {
  //       path: "whatsapp",
  //       name: "reporting.broadcast-message.whatsapp",
  //       component: () =>
  //         import("@/views/reporting/pages/ReportingMainPage.vue"),
  //     },
  //   ],
  // },
  // {
  //   path: "role-history-activity",
  //   name: "reporting.role-history-activity",
  //   component: () => import("@/views/reporting/pages/ReportingMainPage.vue"),
  // },
  // {
  //   path: "agent-activities",
  //   name: "reporting.agent-activites",
  //   component: () => import("@/views/reporting/pages/ReportingMainPage.vue"),
  // },
];
export default reportingRoute;
