<template>
  <b-modal
    hide-header
    hide-footer
    class="border-0"
    v-model="isOpenModal"
    centered
    size="md"
    @hidden="hideModal"
    body-class="p-0 m-0 border-0"
    content-class="border-0"
    id="modal-scoped"
  >
    <div
      class="ps-4 pe-3 py-2 bg-primary d-flex justify-content-between rounded-top"
    >
      <h6 class="modal-title text-light my-auto fw-semibold text-uppercase">
        {{ title }}
      </h6>
      <i
        class="ri-close-line text-white fs-4 pointer"
        data-bs-dismiss="modal"
        aria-label="Close"
        @click="handleCloseModal"
        v-if="!hideCloseIcon"
      ></i>
    </div>
    <div class="container p-4">
      <h4></h4>
      <p class="fs-12 mb-4 font-poppins">
        {{ isInformation }}
      </p>
      <div class="p-3 text-center">
        <img src="/img/icons/delete-icon.svg" />
      </div>
      <div class="mt-auto pt-4">
        <div class="d-flex align-items-center">
          <h5 class="flex-grow-1 fs-16 mb-0" id="filetype-title"></h5>
          <div class="flex-shrink-0">
            <button
              class="btn btn-light fw-medium text-decoration-none me-2"
              data-bs-dismiss="modal"
              @click="hideModal"
            >
              <i class="ri-close-line me-1 align-middle"></i> Cancel
            </button>
            <base-button
              :loading="isLoading"
              class="btn btn-primary"
              id="delete-record"
              @click="handleSubmit"
            >
              {{ submitText }}
            </base-button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import animationData1 from "@/components/widgets/gsqxdxog.json";
// import Lottie from "@/components/widgets/lottie.vue";
import { debounce } from "lodash";
export default {
  name: "ModalDelete",
  props: {
    showModal: {
      type: Boolean,
      default: false,
      required: true,
    },
    title: {
      type: String,
      default: "Are You Sure Want to Delete ?",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isInformation: {
      type: String,
      default:
        "Deleting your order will remove all of your information from our database.",
    },
    submitText: {
      type: String,
      default: "Yes, Delete It",
    },
  },
  components: {},
  data() {
    return {
      isOpen: this.showModal,
      isOpenModal: this.showModal,
      defaultOptions1: {
        animationData: animationData1,
        primaryColor: "#F65757", // New color here
        secondaryColor: "#F65757",
      },
    };
  },
  methods: {
    onShowModal() {
      this.isOpenModal = true;
    },
    onHideModal() {
      this.isOpenModal = false;
      this.$emit("onClose");
    },
    handleSubmit() {
      this.onSubmit(this);
    },
    onSubmit: debounce((vm) => {
      vm.$emit("onSubmit");
    }, 500),

    hideModal() {
      this.$emit("onHidden");
    },
  },
  watch: {
    showModal(newVal) {
      if (newVal) {
        this.onShowModal();
      } else {
        this.onHideModal();
      }
    },
  },
};
</script>
