<template>
  <div class="editor p-0" id="ticket-section">
    <menu-bar class="editor__header bg-light" :editor="editor" />
    <div class="input-group border-bottom" v-show="isForward">
      <div class="input-group-prepend border-0">
        <span class="input-group-text border-0 h-100">To :</span>
      </div>
      <b-form-tags
        v-model="recipients"
        tag-variant="primary"
        tag-pills
        add-on-enter
        placeholder=" "
        @input="inputRecipients"
      ></b-form-tags>
    </div>

    <b-modal
      v-model="previewFiles.show"
      hide-footer
      modal-class="flip"
      title="Preview image"
    >
      <form @submit.prevent="submitImage">
        <div class="preview">
          <div
            v-for="(preview, index) in previewFiles.previews"
            :key="preview.name"
            class="preview-image"
          >
            <div class="image">
              <img :src="preview.image" alt="preview" />
            </div>

            <button
              type="button"
              class="btn-action"
              @click="removeImage(index)"
            >
              <i class="ri-close-line"></i>
            </button>
          </div>
        </div>

        <div style="display: flex; gap: 5px; justify-content: end">
          <button
            type="button"
            class="btn btn-danger"
            @click="previewFiles.show = false"
          >
            Close
          </button>

          <button type="submit" class="btn btn-success">
            Add to description
          </button>
        </div>
      </form>
    </b-modal>

    <editor-content
      class="overflow-y-auto"
      :editor="editor"
      style="min-height: 100px"
      @paste="handlePaste"
    />
  </div>
</template>

<script>
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import { Editor, EditorContent } from "@tiptap/vue-3";
import MenuBar from "./tiptap/MenuBar.vue";
import Link from "@tiptap/extension-link";
import Image from "@tiptap/extension-image";
import HardBreak from "@tiptap/extension-hard-break";
// import Heading from "@tiptap/extension-heading";
// import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Bold from "@tiptap/extension-bold";
import Italic from "@tiptap/extension-italic";
import Strike from "@tiptap/extension-strike";
import BulletList from "@tiptap/extension-bullet-list";
import OrderedList from "@tiptap/extension-ordered-list";
import ListItem from "@tiptap/extension-list-item";
import TextAlign from "@tiptap/extension-text-align";
import TaskList from "@tiptap/extension-task-list";
import TaskItem from "@tiptap/extension-task-item";
import { httpClientOmni } from "@/libraries";

export default {
  name: "Tiptap",
  components: {
    EditorContent,
    MenuBar,
  },

  props: {
    modelValue: {
      type: String,
      default: "",
    },
    image: {
      type: Object,
    },
  },

  emits: ["update:modelValue"],

  data() {
    return {
      editor: null,
      recipients: [],
      isForward: false,

      previewFiles: {
        show: false,
        data: [],
        previews: [],
      },
    };
  },

  watch: {
    image(file) {
      if (file) {
        this.editor
          .chain()
          .focus()
          .setImage({ src: URL.createObjectURL(file) })
          .run();
      }
    },
    modelValue(value) {
      const isSame = this.editor.getHTML() === value;

      if (isSame) return;

      this.editor.commands.setContent(value, false);
    },
  },

  mounted() {
    this.emitter.on("reply:hidden", () => {
      this.editor = null;
    });
    this.emitter.on("reply:onForward", (payload) => {
      if (payload) {
        this.isForward = true;
      }
    });
    this.emitter.on("reply:uploadImage", (payload) => {
      if (payload) {
        this.editor
          .chain()
          .focus()
          .setImage({ src: URL.createObjectURL(payload) })
          .run();
      }
    });
    this.emitter.on("tiptap:onReset", () => {
      this.isForward = false;
      this.recipients = [];
    });
    this.editor = new Editor({
      extensions: [
        StarterKit,
        Link,
        Image,
        Bold,
        Italic,
        Paragraph,
        Text,
        HardBreak.configure({
          HTMLAttributes: {
            style: "line-height: 1",
          },
        }),
        Placeholder.configure({
          placeholder: "Type a message",
        }),
        BulletList,
        OrderedList,
        ListItem,
        TextAlign.configure({
          types: ["heading", "paragraph"],
        }),
        Strike,
        TaskList,
        TaskList,
        TaskItem.configure({
          nested: true,
        }),
      ],
      content: this.modelValue,
      editorProps: {
        handleDrop: function () {
          return true;
        },
      },
      onUpdate: () => {
        this.$emit("update:modelValue", this.editor.getHTML());
      },
    });
  },
  methods: {
    inputRecipients() {
      this.$emit("editorData", this.recipients);
    },
    handlePaste(e) {
      const files = e.clipboardData.files;
      if (files.length > 0) {
        this.previewFiles.show = true;
        this.previewFiles.previews = [];
        this.previewFiles.data = [];

        const allowedImage = ["png", "jpg", "jpeg", "gif", "svg", "webp"];

        files.forEach((file) => {
          const ext = file.name.split(".").pop();
          const check = allowedImage.find((r) => r === ext.toLowerCase());

          if (check) {
            const image = URL.createObjectURL(file);
            this.previewFiles.data.push(file);
            this.previewFiles.previews.push({
              name: file.name,
              image,
            });
          }
        });
      }
    },

    removeImage(index) {
      this.previewFiles.previews.splice(index, 1);
      this.previewFiles.data.splice(index, 1);

      if (this.previewFiles.previews.length === 0) {
        this.previewFiles.show = false;
      }
    },

    randomFilename(file) {
      const extension = file.name.split(".").pop();
      const randomName = Math.random().toString(36).substring(2, 15);
      return `${randomName}.${extension}`;
    },

    async submitImage() {
      const fileLen = this.previewFiles.data.length;
      if (fileLen) {
        for (let i = 0; i < fileLen; i++) {
          try {
            const formData = new FormData();
            formData.append("channel", "ticket");
            formData.append(
              "file",
              this.previewFiles.data[i],
              this.randomFilename(this.previewFiles.data[i])
            );

            const req = await httpClientOmni.post("/upload-file", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });

            this.editor.commands.setImage({
              src: req.data.data.fileUrl,
            });
            this.editor.commands.createParagraphNear();
          } catch (error) {
            console.log(error);
          }
        }
      }

      this.previewFiles.show = false;
    },
  },
  beforeUnmount() {
    this.editor.destroy();
  },
};
</script>
<style lang="scss">
.editor {
  display: flex;
  flex-direction: column;
  color: #0d0d0d;
  background-color: #fff;
  border: 1px solid #c5e1f2ab;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;

  &__header {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    flex-wrap: wrap;
    padding: 0.25rem;
    border-bottom: 1px solid #c5e1f2ab;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }

  &__content {
    padding: 1.25rem 1rem;
    flex: 1 1 auto;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    max-height: 26rem;
  }

  &__footer {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    white-space: nowrap;
    border-top: 3px solid #c5e1f2ab;
    font-size: 12px;
    font-weight: 600;
    color: #0d0d0d;
    white-space: nowrap;
    padding: 0.25rem 0.75rem;
  }

  /* Some information about the status */
  &__status {
    display: flex;
    align-items: center;
    border-radius: 5px;

    &::before {
      content: " ";
      flex: 0 0 auto;
      display: inline-block;
      width: 0.5rem;
      height: 0.5rem;
      background: rgba(#0d0d0d, 0.5);
      border-radius: 50%;
      margin-right: 0.5rem;
    }

    &--connecting::before {
      background: #616161;
    }

    &--connected::before {
      background: #b9f18d;
    }
  }

  &__name {
    button {
      background: none;
      border: none;
      font: inherit;
      font-size: 12px;
      font-weight: 600;
      color: #0d0d0d;
      border-radius: 0.4rem;
      padding: 0.25rem 0.5rem;

      &:hover {
        color: #fff;
        background-color: #0d0d0d;
      }
    }
  }
}
</style>

<style lang="scss">
#ticket-section {
  /* Give a remote user a caret */
  .collaboration-cursor__caret {
    position: relative;
    margin-left: -1px;
    margin-right: -1px;
    border-left: 1px solid #0d0d0d;
    border-right: 1px solid #0d0d0d;
    word-break: normal;
    pointer-events: none;
  }

  /* Render the username above the caret */
  .collaboration-cursor__label {
    position: absolute;
    top: -1.4em;
    left: -1px;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    user-select: none;
    color: #0d0d0d;
    padding: 0.1rem 0.3rem;
    border-radius: 3px 3px 3px 0;
    white-space: nowrap;
  }

  /* Basic editor styles */
  .ProseMirror {
    min-height: 6rem;
    line-height: 1;
    padding: 1rem;
    > * + * {
      margin-top: 0.75em;
    }

    ul,
    ol {
      padding: 0 1rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 1.1;
    }

    a {
      color: #68cef8;
    }

    code {
      background-color: rgba(#616161, 0.1);
      color: #616161;
    }

    pre {
      background: #0d0d0d;
      color: #fff;
      font-family: "JetBrainsMono", monospace;
      padding: 0.75rem 1rem;
      border-radius: 0.5rem;

      code {
        color: inherit;
        padding: 0;
        background: none;
        font-size: 0.8rem;
      }
    }

    mark {
      background-color: #faf594;
    }

    img {
      width: 250px;
    }

    hr {
      margin: 1rem 0;
    }

    blockquote {
      padding-left: 1rem;
      border-left: 2px solid rgba(#0d0d0d, 0.1);
    }

    hr {
      border: none;
      border-top: 2px solid rgba(#0d0d0d, 0.1);
      margin: 2rem 0;
    }

    ul[data-type="taskList"] {
      list-style: none;
      padding: 0;

      li {
        display: flex;
        align-items: center;

        > label {
          flex: 0 0 auto;
          margin-right: 0.5rem;
          user-select: none;
        }

        > div {
          flex: 1 1 auto;
        }
      }
    }
  }
}
</style>
