<template>
  <div class="d-flex align-items-center align-items-center">
    <FileUpload
      class="w-100 uploader"
      :extensions="extension"
      :accept="accept"
      :multiple="multiple"
      :size="size"
      v-model="files"
      @input-filter="inputFilter"
      @input-file="inputFile"
      @input="uploading"
      :ref="id"
      :input-id="id"
      drop
      :drop-directory="false"
    >
      <!-- Drop Zoon -->
      <div
        id="dropZoon"
        class="upload-area__drop-zoon drop-zoon"
        :class="{ 'border-dashed': borderDash }"
      >
        <div
          class="align-items-center d-flex flex-column"
          v-if="files.length == 0 && persistLabel == false"
        >
          <span class="drop-zoon__icon text-primary">
            <i class="bx bx-cloud-upload text-muted"></i>
          </span>
          <p class="drop-zoon__paragraph">
            {{ label }}
          </p>
        </div>
        <div
          class="align-items-center d-flex flex-column"
          v-if="persistLabel == true"
        >
          <span class="drop-zoon__icon text-primary">
            <i class="bx bx-cloud-upload text-muted"></i>
          </span>
          <p class="drop-zoon__paragraph">
            Drop your file here or Click to browse
          </p>
        </div>
        <span v-show="isUploading" class="drop-zoon__loading-text">
          Please Wait
        </span>
        <div
          class="d-flex flex-wrap"
          v-if="useThumbnail && thumbnailType == 'image' && !loadingAttach"
        >
          <div v-for="(val, i) in files" :key="i">
            <img
              :src="val.thumb"
              alt="Preview Image"
              id="previewImage"
              class="drop-zoon__preview-image"
              draggable="false"
            />
            <span
              class="position-absolute cartitem-badge fs-10 translate-middle badge rounded-pill bg-danger pointer p-2"
              style="z-index: 99"
              @click="removeFile(val.id)"
            >
              <i class="ri-delete-bin-2-fill fs-16"></i>
            </span>
          </div>
        </div>
        <div
          class="d-flex"
          v-if="useThumbnail && thumbnailType == 'name' && !loadingAttach"
        >
          <div class="row">
            <div class="col mb-2" v-for="(val, i) in files" :key="i">
              <div
                class="d-flex align-items-center border border-dashed p-2 rounded"
              >
                <div class="flex-shrink-0 avatar-sm">
                  <div class="avatar-title bg-light rounded">
                    <i
                      v-if="val.type.includes('image/')"
                      class="ri-image-line fs-20 text-primary"
                    ></i>
                    <i
                      v-else-if="val.type.includes('pdf')"
                      class="ri-file-pdf-line fs-20 text-primary"
                    ></i>
                    <i
                      v-else-if="val.type.includes('word')"
                      class="ri-file-word-line fs-20 text-primary"
                    ></i>
                    <i
                      v-else-if="val.type.includes('csv')"
                      class="ri-file-excel-fill fs-20 text-success"
                    ></i>
                    <i v-else class="ri-file-3-line fs-20 text-primary"></i>
                  </div>
                </div>
                <div class="flex-grow-1 ms-3">
                  <h6 class="mb-1">
                    <a href="javascript:void(0);">
                      {{ truncateText(val.name, 30) }}
                    </a>
                  </h6>
                  <small class="text-muted">
                    {{ floor(divide(val.size, 1000000), 2) }} MB
                  </small>
                </div>
                <div class="hstack gap-3 fs-16" style="z-index: 99">
                  <a
                    href="javascript:void(0);"
                    @click="removeFile(val.id)"
                    class="text-muted"
                    ><i class="ri-delete-bin-line"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="loadingAttach" class="h-100 text-center mb-2 mt-5">
        <span
          class="spinner-border"
          style="width: 2rem; height: 2rem"
          role="status"
        >
        </span>
      </div>
      <!-- End Drop Zoon -->
    </FileUpload>
  </div>
</template>
<script>
import FileUpload from "vue-upload-component";
import { ref } from "vue";
import { floor, divide } from "lodash";
import convertHeicToJpeg from "@/utils/convertHeic";
export default {
  name: "Uploader",
  components: {
    FileUpload,
  },
  props: {
    extension: {
      type: String,
      default: "gif,jpg,jpeg,png,webp",
    },
    accept: {
      type: String,
      default: "image/png,image/gif,image/jpeg,image/webp,image/jpg",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    useThumbnail: {
      type: Boolean,
      default: true,
    },
    thumbnailType: {
      type: String,
      default: "image",
    },
    label: {
      type: String,
      default: "Drop your file here or click to browse",
    },
    triggerResetUploadedFiles: {
      type: Number,
      default: 0,
    },
    triggerRemoveFile: {
      type: String,
      default: "fileId",
    },
    id: {
      type: String,
      default: "upload",
    },
    persistLabel: {
      type: Boolean,
      default: false,
    },
    borderDash: {
      type: Boolean,
      default: true,
    },
    size: {
      type: Number,
      default: 1024 * 1024 * 10,
    },
  },
  data() {
    return {
      divide,
      floor,
      upload: ref(null),
      files: ref([]),
      isUploading: false,
      loadingAttach: false,
    };
  },
  watch: {
    triggerResetUploadedFiles: {
      handler() {
        this.files = ref([]);
      },
      immediate: true,
    },
    triggerRemoveFile(newVal) {
      this.removeFile(newVal);
    },
  },
  methods: {
    inputFilter(newFile, oldFile, prevent) {
      this.isUploading = true;
      if (newFile && !oldFile) {
        // Before adding a file
        // 添加文件前
        // Filter system files or hide files
        // 过滤系统文件 和隐藏文件
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          this.isUploading = false;
          return prevent();
        }

        // Filter php html js file
        // 过滤 php html js 文件
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          this.isUploading = false;
          return prevent();
        }
      }

      if (
        newFile &&
        newFile.error === "" &&
        newFile.file &&
        (!oldFile || newFile.file !== oldFile.file)
      ) {
        // Create a blob field
        // 创建 blob 字段
        newFile.blob = "";
        let URL = window.URL || window.webkitURL;
        if (URL) {
          newFile.blob = URL.createObjectURL(newFile.file);
        }
        // Thumbnails
        // 缩略图
        newFile.thumb = "";
        if (newFile.blob && newFile.type.substr(0, 6) === "image/") {
          newFile.thumb = newFile.blob;
        }
      }
    },
    async inputFile(newFile, oldFile) {
      this.isUploading = false;
      if (newFile && !oldFile) {
        // add
        if (newFile.type === "image/heic") {
          this.loadingAttach = true;
          try {
            const jpegBlob = await convertHeicToJpeg(newFile.file);
            const nameJpeg = newFile.file.name.split(".")[0] + ".jpeg";
            const typeJpeg = "image/jpeg";
            const modifiedFile = new File([jpegBlob], nameJpeg, {
              type: typeJpeg,
              lastModified: new Date().getTime(),
            });

            this.$emit("onAdd", modifiedFile);
            console.log("Converted add:", modifiedFile);
          } catch (error) {
            console.error("Error converting HEIC to JPEG:", error);
          }
        } else {
          this.$emit("onAdd", newFile);
          console.log("add", newFile);
        }
        this.loadingAttach = false;
      }
      if (newFile && oldFile) {
        // update
        if (newFile.type === "image/heic") {
          this.loadingAttach = true;
          try {
            const jpegBlob = await convertHeicToJpeg(newFile.file);
            const nameJpeg = newFile.file.name + ".jpeg";
            const typeJpeg = "image/jpeg";
            const modifiedFile = new File([jpegBlob], nameJpeg, {
              type: typeJpeg,
              lastModified: new Date().getTime(),
            });

            this.$emit("onUpdate", newFile);
            console.log("Converted update:", modifiedFile);
          } catch (error) {
            console.error("Error converting HEIC to JPEG:", error);
          }
        } else {
          this.$emit("onUpdate", newFile);
          console.log("update", newFile);
        }
        this.loadingAttach = false;
      }
      if (!newFile && oldFile) {
        // remove
        this.$emit("onRemove", oldFile);
        console.log("remove", oldFile);
      }
    },
    removeFile(id) {
      var index = this.files.findIndex(function (o) {
        return o.id === id;
      });
      if (index !== -1) this.files.splice(index, 1);
    },
    uploading(event) {
      event.preventDefault();
      console.log("EVENT___", event);
      this.$emit("uploading", event);
    },
  },
};
</script>

<style>
:root {
  --clr-white: rgb(255, 255, 255);
  --clr-black: rgb(0, 0, 0);
  --clr-light: rgb(245, 248, 255);
  --clr-light-gray: rgb(196, 195, 196);
  --clr-blue: rgb(63, 134, 255);
  --clr-light-blue: rgb(171, 202, 255);
}
/* Upload Area */
.upload-area {
  width: 100%;
  max-width: 25rem;
  background-color: var(--clr-white);
  box-shadow: 0 10px 60px rgb(218, 229, 255);
  border: 2px solid var(--clr-light-blue);
  border-radius: 24px;
  padding: 2rem 1.875rem 5rem 1.875rem;
  margin: 0.625rem;
  text-align: center;
}

.upload-area--open {
  /* Slid Down Animation */
  animation: slidDown 500ms ease-in-out;
}

@keyframes slidDown {
  from {
    height: 28.125rem; /* 450px */
  }

  to {
    height: 35rem; /* 560px */
  }
}

/* Header */
.upload-area__header {
}

.upload-area__title {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 0.3125rem;
}

.upload-area__paragraph {
  font-size: 0.9375rem;
  color: var(--clr-light-gray);
  margin-top: 0;
}

.upload-area__tooltip {
  position: relative;
  color: var(--clr-light-blue);
  cursor: pointer;
  transition: color 300ms ease-in-out;
}

.upload-area__tooltip:hover {
  color: var(--clr-blue);
}

.upload-area__tooltip-data {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -125%);
  min-width: max-content;
  background-color: var(--clr-white);
  color: var(--clr-blue);
  border: 1px solid var(--clr-light-blue);
  padding: 0.625rem 1.25rem;
  font-weight: 500;
  opacity: 0;
  visibility: hidden;
  transition: none 300ms ease-in-out;
  transition-property: opacity, visibility;
}

.upload-area__tooltip:hover .upload-area__tooltip-data {
  opacity: 1;
  visibility: visible;
}

/* Drop Zoon */
.upload-area__drop-zoon {
  /* z-index: 99; */
  position: relative;
  height: 11.25rem; /* 180px */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 15px;
  /* margin-top: 2.1875rem; */
  cursor: pointer;
  transition: border-color 300ms ease-in-out;
}

.border-dashed {
  border: 2px dashed var(--vz-primary);
}

.upload-area__drop-zoon:hover {
  border-color: var(--clr-blue);
}

.drop-zoon__icon {
  display: flex;
  font-size: 3.75rem;
  color: var(--clr-blue);
  transition: opacity 300ms ease-in-out;
}

.drop-zoon__paragraph {
  font-size: 0.9375rem;
  color: var(--clr-light-gray);
  margin: 0;
  margin-top: 0.625rem;
  transition: opacity 300ms ease-in-out;
}

.drop-zoon:hover .drop-zoon__icon,
.drop-zoon:hover .drop-zoon__paragraph {
  opacity: 0.7;
}

.drop-zoon__loading-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* display: none; */
  color: var(--clr-light-blue);
  z-index: 10;
}

.drop-zoon__preview-image {
  max-height: 120px;
  /* position: absolute; */
  /* top: 0;
  left: 0;
  width: 100%;
  height: 100%; */
  object-fit: contain;
  padding: 0.3125rem;
  border-radius: 10px;
  /* display: none; */
  z-index: 1000;
  transition: opacity 300ms ease-in-out;
}

.drop-zoon:hover .drop-zoon__preview-image {
  opacity: 0.8;
}

.drop-zoon__file-input {
  /* display: none; */
}

/* (drop-zoon--over) Modifier Class */
.drop-zoon--over {
  border-color: var(--clr-blue);
}

.drop-zoon--over .drop-zoon__icon,
.drop-zoon--over .drop-zoon__paragraph {
  opacity: 0.7;
}

/* (drop-zoon--over) Modifier Class */
.drop-zoon--Uploaded {
}

.drop-zoon--Uploaded .drop-zoon__icon,
.drop-zoon--Uploaded .drop-zoon__paragraph {
  display: none;
}
.btn-remove {
  position: absolute;
  z-index: 9999999;
  top: 4%;
  right: 31%;
}
</style>
<style lang="scss">
.file-uploads {
  label {
    cursor: pointer;
  }
}
</style>
